<template>
  <div>
    <video
      muted
      autoplay="autoplay"
      controls
      width="250"
    >
      <source
        src="https://download.YeloBet.com/123.mp4"
        type="video/mp4"
      >
    </video>
    <v-btn @click="download('https://download.YeloBet.com/123.php')">
      下载
    </v-btn>
  </div>
</template>

<script>
  export default {
    data: () => ({

    }),
    methods: {
      download(url)
      {
        location.href = url
      }
    }
  }
</script>